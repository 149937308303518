<template>
  <qtm-dialog-card
    v-bind="$attrs"
    :title="title"
    width="500px"
    @update:model-value="$emit('update:model-value', $event)"
  >
    <div>
      <div v-if="failed">
        <div class="mb-4">
          <span class="text-interactive" v-text="header" />
          failed to Sync with your accounting software due to the following error message:
        </div>
        <div class="mb-5 qtm-h4 text-secondary">
          <div v-if="currentProblem" v-text="currentProblem.message" />
          <div v-else>
            <div v-for="(message, i) in messages" :key="i" v-text="message" />
          </div>
        </div>
        <div v-if="currentProblem">
          Click below to resolve issue.
        </div>
        <div v-else>
          Please take the necessary action and sync again.
        </div>
      </div>
      <div v-else-if="pending" class="mb-5 text-secondary">
        This {{ type }} has been approved and is now being sent to your accounting software.
        This may take a few minutes.
      </div>
      <div v-else class="mb-5 text-secondary">
        <div v-for="(message, i) in messages" :key="i" v-text="message" />
      </div>
    </div>
    <clear-accounting-error-dialog
      v-model="clearError"
      :endpoint="endpoint"
      :object-id="objectId"
      @cleared="errorCleared"
    />
    <resolve-config-problem-dialog
      v-if="showResolutionDialog"
      v-model="showResolutionDialog"
      :problem="currentProblem"
      :supplier-mapping="isMissingSupplierMapping"
      @resolved="resolved"
    />
    <template v-if="failed" v-slot:actions>
      <v-spacer />
      <qtm-btn :loading="loading" tertiary size="x-large" @click="$emit('update:model-value', false)">
        Close
      </qtm-btn>
      <qtm-btn v-if="isStaff" :loading="loading" secondary size="x-large" @click="clearError = true">
        Clear Error
      </qtm-btn>
      <qtm-btn v-if="currentProblem" :loading="loading" size="x-large" @click="showResolutionDialog = true">
        {{ resolveCta }}
      </qtm-btn>
      <qtm-btn v-else :loading="loading" size="x-large" @click="syncAgain">
        Sync Again
      </qtm-btn>
    </template>
    <template v-else v-slot:actions>
      <v-spacer />
      <qtm-btn x-large @click="$emit('update:model-value', false)">
        Ok
      </qtm-btn>
    </template>
  </qtm-dialog-card>
</template>

<script setup lang="ts">
import ClearAccountingErrorDialog from '@/components/accounting/clear-accounting-error-dialog.vue'
import ResolveConfigProblemDialog from '@/components/accounting/resolve-config-problem-dialog.vue'

export interface Props {
  accountingMessage?: string
  accountingSuccess?: boolean | null
  endpoint: 'expenses' | 'invoices' | 'purchaseOrders' | 'receipts'
  header: string
  objectId: number
  problemObjectId?: number
  problemObjectType: 'expense' | 'invoice' | 'purchase_order'
  resolveData?: Record<string, any>
  type: string
}

const props = defineProps<Props>()
const emit = defineEmits(['update:accounting-success', 'update:model-value'])

const clearError = ref(false)
const loading = ref(false)
const problems = ref<any[]>([])
const showResolutionDialog = ref(false)

const authStore = useAuthStore()
const isStaff = authStore.isStaff

const currentProblem = computed(() => problems.value[0])
const failed = computed(() => props.accountingSuccess === false)
const pending = computed(() => props.accountingSuccess === null)
const messages = computed(() => props.accountingMessage?.split('\n') ?? [])
const isMissingSupplierMapping = computed(() => props.accountingMessage?.toLowerCase() === 'missing supplier mapping')
const resolveCta = computed(() => (isMissingSupplierMapping.value ? 'Map Supplier & Sync' : 'Resolve'))
const title = computed(() => {
  if (pending.value) {
    return 'Accounting Sync Pending'
  }
  if (props.accountingSuccess) {
    return 'Accounting Sync Successful'
  }
  return 'Accounting Sync Failed'
})

const errorCleared = () => {
  emit('update:accounting-success', true)
  emit('update:model-value', false)
}
const { $api, $error, $toast } = useNuxtApp()
const syncAgain = async () => {
  loading.value = true
  try {
    await $api.v1[props.endpoint].resolveAccountingFailure(props.objectId, props.resolveData)
    emit('update:model-value', false)
    emit('update:accounting-success', null)
  }
  catch (error) {
    $error.report(error)
  }
  loading.value = false
}

const fetchConfigProblems = async () => {
  loading.value = true
  try {
    problems.value = await $api.v1.accountingConfigProblems.list({
      [props.problemObjectType]: props.problemObjectId || props.objectId,
    })
  }
  catch (error) {
    $error.report(error)
  }
  loading.value = false
}

watchEffect(() => {
  if (props.objectId && props.problemObjectType) {
    fetchConfigProblems()
  }
})

const resolved = (problem: any) => {
  problems.value = problems.value.filter(p => p.id !== problem.id)

  if (problems.value.length === 0) {
    emit('update:accounting-success', null)
    $toast.success('Sync attempt in progress')
    emit('update:model-value', false)
  }
}
</script>
