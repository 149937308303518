<template>
  <accounting-sync-dialog
    v-bind="$attrs"
    :endpoint="receiptLogId ? 'receipts' : 'purchaseOrders'"
    :header="header"
    :model-value="showDialog"
    :object-id="receiptLogId || poId"
    :problem-object-id="poId"
    problem-object-type="purchase_order"
    :resolve-data="{ change_order: revisionId }"
    :type="revisionNumber ? 'change order' : 'PO'"
    @update:model-value="$emit('update:show-dialog', $event)"
  />
</template>

<script setup lang="ts">
import AccountingSyncDialog from '@/components/accounting/accounting-sync-dialog.vue'

export interface Props {
  poId: number
  poNumber: string
  receiptLogId?: number
  revisionId?: number
  revisionNumber: number
  showDialog?: boolean
}

const props = defineProps<Props>()
defineEmits(['update:show-dialog'])

const header = computed(() => {
  const common = `PO ${props.poNumber}-${props.revisionNumber}`

  if (props.receiptLogId) {
    return `Receipt Log for ${common}`
  }

  return common
})
</script>
