<template>
  <v-btn :color="status.color" variant="text" @click="openDialog = true">
    <v-icon>
      {{ status.icon }}
    </v-icon>
    Accounting Sync:
    {{ status.message }}
    <slot :open="openDialog" :close="close" />
  </v-btn>
</template>

<script setup lang="ts">
export interface Props {
  message?: string | null
  success?: boolean | null
}

const props = defineProps<Props>()

const openDialog = ref(false)

const close = () => {
  openDialog.value = false
}

const overrideStatus = computed(() => {
  return props.message?.toLowerCase() === 'missing supplier mapping' ? 'Supplier Not Mapped' : null
})

const status = computed(() => {
  if (props.success === null) {
    return {
      color: 'primary',
      icon: 'mdi-update',
      message: 'Pending',
    }
  }
  if (props.success) {
    return {
      color: 'interactive',
      icon: 'mdi-sync',
      message: 'Successful',
    }
  }
  return {
    color: 'error',
    icon: 'mdi-sync-alert',
    message: overrideStatus.value || 'Error',
  }
})
</script>
